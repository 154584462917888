@import url(https://fonts.googleapis.com/css?family=Overlock:400,400i,700|Oleo+Script);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*************************************************************
    BASIC STYLES
  **************************************************************/

*, *::before, *::after, :root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
	overflow: hidden;
    height: 100%;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    font-family: 'Overlock', Arial, Helvetica, sans-serif;
	-ms-scroll-snap-type: y mandatory;
	    scroll-snap-type: y mandatory;
}

/*************************************************************
    SECTIONS
  **************************************************************/
.container {
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
}
section {
    position: relative;
    min-height: 100vh;
    width: 100%;
    position: relative;
    -webkit-transform-style: inherit;
            transform-style: inherit;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
}

.section1 {
    position: relative;
    width: 100%;
}

.section1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-size: cover;
    background-color: black;
    min-height: 100%;
    z-index: -2;
}

.section2, .section4  {
    background: #fafafa;
    box-shadow: 0 0 20px #333;
    z-index: 1;
}

.section3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    /* background: url('../assets/sow.JPG') top center; */
    background-size: cover;
    background-color: #333232;
    z-index: -1;
}

/*************************************************************
    HEADINGS
  **************************************************************/

.center-section {
    font-size: 4rem;
    text-align: center;
    padding: 1rem;
    color: white;
}

.section2,.section4 div {
    z-index: 3;
    box-shadow: none;
}

.section3 div {
    z-index: 3;
}

.black {
    color: black;
}

.title {
    font-size: 38px;
    padding-bottom: 25px;
    font-weight: bold;
}

.email {
    font-size: 32px;
    padding-bottom: 8px;
}
.about {
    font-size: 32px;
    padding-bottom: 25px;
    max-width: 750px;
    text-align: left;
    scroll-snap-align: start;
}

.big-nav {
    width: 100%;
    height: 50px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    top: 0;
}

.nav-text {
    font-size: 22px;
    color: white;
    cursor: pointer;
}

.scroll-top {
    position: fixed;
    bottom: 24px;
    right: 25px;
    z-index: 10;
    cursor: pointer;
    height: 60px;
}

@media only screen and (max-width: 600px) {
    .logo-image {
        height: 250px;
    }
    .title {
        font-size: 28px
    }
    .email {
        font-size: 18px
    }
    .about {
        font-size: 18px
    }
}
