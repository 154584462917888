/*************************************************************
    BASIC STYLES
  **************************************************************/

*, *::before, *::after, :root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import 'https://fonts.googleapis.com/css?family=Overlock:400,400i,700|Oleo+Script';
html {
	overflow: hidden;
    height: 100%;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    transform-style: preserve-3d;
    font-family: 'Overlock', Arial, Helvetica, sans-serif;
	scroll-snap-type: y mandatory;
}
